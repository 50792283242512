<template>
  <b-overlay :show="formSubmitted" :spinner-variant="colorVariant('', 'primary')" variant="dark" opacity="0.85"
    :spinner-type="spinnerType" spinner rounded="sm">
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title class="mb-1">
            Visitas dos Últimos Dias
          </b-card-title>
          <b-card-sub-title v-if="!account">
            <span v-if="selectedAccount">{{ selectedAccount.nickname }}</span>
            <span v-else>{{ $t('components.cards.common.select_account') }}</span>
          </b-card-sub-title>
        </div>
        <div class="d-flex align-items-center">
          <simple-account-selector v-if="!account" @change="changeSelectedAccount"></simple-account-selector>
          <a @click="getChartData">
            <feather-icon icon="RotateCwIcon" size="16" />
          </a>
        </div>
      </b-card-header>

      <b-card-body>
        <vue-apex-charts type="area" height="400" :options="cardMeliVisitsOptions.chartOptions" :series="series" />
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BOverlay,
} from 'bootstrap-vue'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import { $themeConfig } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import { parseISO, format } from 'date-fns'
import cardMeliVisitsOptions from './_cardMeliVisitsOptions'
import simpleAccountSelector from './components/SimpleAccountSelector.vue'
import meliUser from '@/services/meli/users'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    VueApexCharts,
    simpleAccountSelector,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
  },
  mixins: [
    variants,
    handleAxiosException,
  ],
  data() {
    return {
      selectedAccount: null,
      cardMeliVisitsOptions,
      series: [],
      confirm: false,
      formSubmitted: false,
    }
  },
  computed: {
    accountLoaded() {
      return this.selectedAccount.loading >= 100
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
  mounted() {
    if (this.account) {
      this.selectedAccount = this.account
    }
    this.getChartData()
  },
  methods: {
    getChartData() {
      if (this.selectedAccount) {
        this.formSubmitted = true
        this.series = []
        meliUser.getItemsVisits(this.selectedAccount.access_token, this.selectedAccount.seller_id).then(result => {
          if (result.httpCode === 200) {
            const series = []
            result.body.results.forEach(element => {
              series.push({ x: format(parseISO(element.date), 'dd/MM'), y: element.total })
            })
            this.series.push({ data: series })
          }
          this.formSubmitted = false
        }).catch(data => {
          // context.commit('UPDATE_STATUS_MESSAGE', data)
          console.log(data)
          this.formSubmitted = false
        })
      }
    },
    changeSelectedAccount(selected) {
      this.selectedAccount = selected
      this.getChartData()
    },
  },
}
</script>
