<template>
  <b-card class="text-center">
    <div class="progress-wrapper">
      <b-card-text class="mb-0">
        <b-row>
          <b-col cols="12" class="mb-1"><b-img :src="require('@/assets/images/mdlivre/card_' + account.type + '.png')"></b-img></b-col>
          <b-col cols="12" class="mb-1"><b-badge variant="primary">{{ account.seller_id }} - {{ account.nickname }}</b-badge></b-col>
          <b-col cols="12"  class="mb-1" v-if="!initialize && accountLoaded && (viewOption==='')">
            <strong>
              <span v-if="!accountLoaded" class="text-primary">{{ $t('components.cards.card_account.loading_account') }}</span>
              <span v-if="accountLoaded" class="text-success">{{ $t('components.cards.card_account.account_loaded') }}</span>
            </strong>
          </b-col>
          <b-col cols="12">
            <b-button v-if="false" variant="outline-primary" size="sm" class="mr-50">
              <feather-icon icon="SettingsIcon" size="16" />
              <span class="d-none d-md-inline">&nbsp;{{ $t('accounts.manage') }}</span>
            </b-button>
            <b-button variant="outline-danger" size="sm" @click="confirm=true">
              <feather-icon icon="Trash2Icon" size="16" />
              <span class="d-none d-md-inline">&nbsp;{{ $t('accounts.remove') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
      <b-progress v-if="false && !accountLoaded" v-model="account.loading" max="100" />
      <b-spinner v-if="false && !accountLoaded" :type="spinnerType" :variant="colorVariant('', 'primary')" style="width: 32px; height: 32px;" />
    </div>

    <b-overlay :show="confirm" no-wrap variant="dark" opacity="0.85">
      <template v-slot:overlay>
        <div v-if="formSubmitted" class="text-center rounded">
          <b-spinner class="mr-50" :type="spinnerType" :variant="colorVariant('', 'primary')" style="width: 16px; height: 16px;" />
        </div>
        <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label"
          class="text-center p-3 text-light">
          <p><strong id="form-confirm-label">{{ $t('components.cards.card_account.delete_confirm_message', { 'account': account.nickname }) }}</strong></p>
          <div class="d-flex">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="primary" class="mr-3" @click="deletePermission(account)">
              {{ $t('common.ok') }}
            </b-button>
            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="danger" @click="confirm=false">
              {{ $t('common.cancel') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
    <meli-visits v-if="viewOption==='visits'" :account="account"></meli-visits>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BProgress, BBadge, BSpinner, BRow, BCol, BButton, BOverlay, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import authorizationsClient from '@core-md/services/authorizations/client'
import Crypt from '@core-md/utils/crypt'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import { $themeConfig } from '@themeConfig'
import meliVisits from './CardMeliVisits.vue'

export default {
  components: {
    BCard,
    BCardText,
    BProgress,
    BBadge,
    BSpinner,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BImg,
    meliVisits,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    initialize: {
      type: Boolean,
      default: true,
    },
    viewOption: {
      type: String,
      default: '',
    },
  },
  directives: {
    Ripple,
  },
  mixins: [
    variants,
    handleAxiosException,
  ],
  data() {
    return {
      confirm: false,
      formSubmitted: false,
    }
  },
  computed: {
    accountLoaded() {
      return this.account.loading >= 100
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
  methods: {
    deletePermission(account) {
      this.formSubmitted = true
      const criteria = {
        id: account.permission.id,
        seller_id: account.seller_id,
      }
      const request = Crypt.signToken(criteria)
      authorizationsClient.deletePermission({
        request,
      })
        .then(response => {
          const result = Crypt.decrypt(response.data.result)
          if (result.status
            && result.status === '0') {
            this.$store.commit('authorizations/DELETE_ACCOUNT', account.seller_id)
            this.$root.showMessageSuccess(this.$i18n.t(result.statusMessageDetail || result.statusMessage), this.$i18n.t('common.messages.success')) // eslint-disable-line prefer-template
          } else {
            this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('accounts.messages.delete_permission_error'))
          }
          this.formSubmitted = false
        })
        .catch(error => {
          this.handleAxiosException(error, 'accounts.messages.delete_permission_error')
          this.formSubmitted = false
        })
    },
  },
}
</script>
