<template>
  <b-dropdown variant="transparent" right class="dropdown-icon-wrapper">
    <template #button-content>
      <feather-icon icon="SettingsIcon" size="16" class="align-middle" :class="{ 'text-danger':!selectedAccount }" />
    </template>
    <b-dropdown-item v-for="account in accounts" :key="account.seller_id" @click="changeSelectedAccount(account)">
      <span class="ml-50">{{ account.nickname }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      selectedAccount: null,
    }
  },
  mounted() {
    this.$store.dispatch('authorizations/refreshAuthorizations')
  },
  computed: {
    accounts() {
      return this.$store.state.authorizations.accounts
    },
  },
  methods: {
    changeSelectedAccount(account) {
      this.selectedAccount = account
      this.$emit('change', account)
    },
  },
}
</script>

<style>

</style>
