<template>
  <div>
    <b-card no-body>
      <HTMLTitle :title="$t('accounts.page_title')"></HTMLTitle>
      <card-header v-if="!initialize" :title="$t('accounts.page_title')" :icon="['fas', 'network-wired']"
        :description="$t('accounts.description')"></card-header>
    </b-card>

    <b-button-toolbar v-if="false && !initialize" aria-label="Toolbar with button groups and dropdown menu">
      <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" right text="Itens por Página" variant="outline-primary">
        <b-dropdown-item @click="lgColumns=12">1</b-dropdown-item>
        <b-dropdown-item @click="lgColumns=6">2</b-dropdown-item>
        <b-dropdown-item @click="lgColumns=4">3</b-dropdown-item>
        <b-dropdown-item @click="lgColumns=3">4</b-dropdown-item>
        <b-dropdown-item @click="lgColumns=2">6</b-dropdown-item>
        <b-dropdown-item @click="lgColumns=1">12</b-dropdown-item>
      </b-dropdown>
      <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mx-1" right text="Visualizar" variant="outline-primary">
        <b-dropdown-item @click="viewOption=''">Nenhum</b-dropdown-item>
        <b-dropdown-item @click="viewOption='visits'">Visitas</b-dropdown-item>
      </b-dropdown>
      <b-button-group>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          Save
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          Cancel
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <br/>
    <b-row v-if="initialize || !accountsLoaded" class="match-height">
      <b-col>
        <b-card class="text-center">
          <div class="progress-wrapper">
            <b-card-text class="mb-0">
              <strong>
                <span class="text-primary" v-if="loadingCounter < maxCounter">{{ $t(statusMessage) }}</span>
                <span class="text-primary" v-if="loadingCounter >= maxCounter">{{ $t('accounts.completed') }}</span>
              </strong><br /><br />
            </b-card-text>
            <b-progress v-model="loadingCounter" :max="maxCounter" />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="6" :lg="lgColumns" v-for="account in accounts" :key="account.meli_user_id" >
        <card-account :account="account" :initialize="initialize" :viewOption="viewOption"></card-account>
      </b-col>
      <b-col v-if="!initialize" md="6" :lg="lgColumns">
        <b-card class="text-center" img-top title="Associar Conta">
          <b-card-text class="mb-0 text-primary">
            <b-row class="match-height">
              <b-col>
                <b-button :variant="colorVariant('outline-')" :to="{ name: 'add_meli' }">
                  <feather-icon icon="PlusIcon" size="16" />
                  <span class="d-none d-md-inline">&nbsp;{{ this.$i18n.t('accounts.add_meli') }}</span>
                  <span class="d-xs-inline d-md-none">&nbsp;Meli</span>
                </b-button>
              </b-col>
              <b-col>
                <b-button v-if="false" :variant="colorVariant('outline-')" :to="{ name: 'add_shopee' }">
                  <feather-icon icon="PlusIcon" size="16" />
                  <span>&nbsp;{{ this.$i18n.t('accounts.add_shopee') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BProgress, BRow, BCol, BButtonToolbar, BButtonGroup, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import variants from '@core-md/mixins/variants'
import handleAxiosException from '@core-md/mixins/errors'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import CardAccount from '@/layouts/components/cards/CardAccount.vue'

export default {
  components: {
    HTMLTitle,
    BRow,
    BCol,
    BCard,
    BCardText,
    BProgress,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    CardAccount,
    CardHeader,
  },
  directives: {
    Ripple,
  },
  mixins: [
    variants,
    handleAxiosException,
  ],
  data() {
    return {
      watchAccountsLoaded: null,
      viewOption: '',
      lgColumns: 3,
      initialize: this.$route.params.initialize ? this.$route.params.initialize === 'initialize' : false,
    }
  },
  mounted() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    if (this.initialize) {
      this.watchAccountsLoaded = this.$store.watch(() => this.$store.state.authorizations.accountsLoaded, loaded => {
        console.log('LOADED')
        console.log(loaded)
        if (loaded) {
          this.$root.showMessageSuccess(this.$i18n.t('accounts.messages.success_detail'), this.$i18n.t('common.messages.success'))
          setTimeout(() => {
            this.$router.push({ name: 'dashboard' })
          }, 5000)
        }
      })
      this.initializeStore()
    }
    this.getAuthorizations()
  },
  beforeDestroy() {
    if (this.watchAccountsLoaded) {
      this.watchAccountsLoaded()
      this.watchAccountsLoaded = null
    }
  },
  destroyed() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  computed: {
    statusMessage() {
      return this.$store.state.authorizations.statusMessage
    },
    loadingCounter() {
      return this.$store.state.authorizations.loadingCounter
    },
    maxCounter() {
      return this.$store.state.authorizations.maxCounter
    },
    accounts() {
      return this.$store.state.authorizations.accounts
    },
    currentAccount() {
      return this.$store.state.authorizations.selectedAccount
    },
    accountsLoaded() {
      return this.$store.state.authorizations.accountsLoaded
    },
  },
  methods: {
    initializeStore() {
      this.$store.dispatch('authorizations/initialize')
    },
    getAuthorizations() {
      this.$store.dispatch('authorizations/refreshAuthorizations')
    },
  },
}
</script>

<style>

</style>
