import { $themeColors } from '@themeConfig'

export default {
  chartOptions: {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    markers: {
      strokeWidth: 7,
      strokeOpacity: 1,
      strokeColors: [$themeColors.light],
      colors: ['#1fb594'],
    },
    colors: ['#1fb594'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      opacity: 0.55,
      type: 'solid',
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      custom(data) {
        return `${'<div class="px-1 py-50"><span>'}Dia ${data.w.globals.categoryLabels[data.dataPointIndex]} = ${data.series[data.seriesIndex][data.dataPointIndex]} Visitas</span></div>`
      },
    },
  },
}
